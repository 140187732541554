<template lang='pug'>
div(v-show="show", class="absolute z-index-1")
  div(:class="'tooltip-bg font-sans p-2 -mt-1 text-sm leading-tight rounded-md shadow-lg z-10 '+ msgStyle")
    div(v-if='multilMsg.length <= 0') {{ msg }}
    div(v-else, v-for='(item,index) in multilMsg', :key='index', class='text-black')
      | {{ item.MissionType + '-' + item.GameType }}
  svg(:class="'tooltip-arrow absolute -top-4 z-10 w-8 h-8 fill-current stroke-current ' + rectStyle", width="8", height="8")
    rect(x="12" y="-5" width="12" height="12" transform="rotate(45)")
</template>

<script>
export default {
  inject: [],
  name: '',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    multilMsg: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: 'w-50'
    }
  },
  data() {
    return {
      rectStyle: 'right-4',
      msgStyle: ''
    }
  },
  mounted() {
    switch (this.position) {
      case 'LeftBottom':
        this.getLeftBottom()
        break
      case 'Center':
        this.getCenter()
        break
      default:
        this.getRightBottom()
        break
    }
  },
  methods: {
    getRightBottom() {
      this.rectStyle = 'right-4'
      this.msgStyle = ''
    },
    getLeftBottom() {
      this.rectStyle = 'right-20'
      this.msgStyle = 'left-40'
    },
    getCenter() {
      this.rectStyle = 'right-4'
      this.msgStyle = 'left-20'
    }
  }
}
</script>
<style lang="scss" scoped>
  .tooltip-bg {
    filter: drop-shadow(2.229px 2.007px 3px rgba(25,16,10,0.59));
    background-color: #efb625;
    min-width: 200px;
    color: #000;
  }
  .z-index-1 {
    z-index: 1;
  }
  .tooltip-arrow {
    color: #efb625;
  }
</style>
