<template lang="pug">
.root
  .total-count
    span Total count:
    b {{ totalCount }}
  .pagelist
    .page-item(data-at-element="pagination-prev-btn" @click="prevPage(currentPage)")
      div
        i.el-icon-caret-left
    .page-item-center
      div {{ currentPage }}
      div /
      div {{ totalPage }}
    .page-item(data-at-element="pagination-next-btn" @click.prevent="nextPage(currentPage)")
      div
        i.el-icon-caret-right
</template>

<script>
export default {
  inject: [],
  name: '',
  components: {},
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
    totalCount: {
      get() {
        return this.total
      },
      set(val) {
        // console.log(val)
        this.$emit('update:total', val)
      }
    },
    totalPage: {
      get() {
        return Math.ceil(this.total / this.limit)
      }
    }
  },
  methods: {
    prevPage(val) {
      val = Number(val) - 1
      if (Number(val) <= 0) {
        this.$emit('pagination', { page: 1, limit: this.pageSize })
      } else {
        this.$emit('pagination', { page: val, limit: this.pageSize })
      }
    },
    nextPage(val) {
      val = Number(val) + 1
      if (this.totalPage === 0) {
        this.$emit('pagination', { page: 1, limit: this.pageSize })
      } else if (Number(val) >= this.totalPage) {
        this.$emit('pagination', { page: this.totalPage, limit: this.pageSize })
      } else {
        this.$emit('pagination', { page: val, limit: this.pageSize })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins';
.root {
  margin-top: 10px;
  position: relative;
  .pagelist {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    .page-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      width: 20px;
      height: 20px;
      background-color: #f5ba25;
      margin: 0 5px;
      color: white;
      outline: none;
      border-radius: 3px;
      color: #594925;
      @include ipad-width {
        width: 40px;
        height: 40px;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .page-item-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      width: 70px;
      height: 20px;
      margin: 0 5px;
      outline: none;
      font-size: 16px;
      border-radius: 5px;
      color: $text-color-gold-dark;
      font-family: Helvetica;
      @include ipad-width {
        font-size: 22px;
      }
      div {
        min-width: 10px;
      }
    }
  }
  .total-count {
    color: $white;
    float: right;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: Helvetica;
    display: flex;
    @include ipad-width {
      font-size: 22px;
    }
    b {
      margin-left: 10px;
    }
  }
}
</style>
