<template lang='pug'>
.root
  audio(id="myMp3", loop)
    source(:src="require('@/assets/music/bgm.mp3')", type="audio/mp3")
</template>

<script>
export default {
  inject: [],
  name: 'Mp3Player',
  components: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {
    play() {
      var d = document.getElementById('myMp3')
      d.play()
    },
    pause() {
      var d = document.getElementById('myMp3')
      d.pause()
    }
  }
}
</script>

<style lang='scss' scoped>
.root {
}
</style>
