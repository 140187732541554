import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import(/* webpackPrefetch: true */ '@/views/Home')
const Lobby = () => import(/* webpackPrefetch: true */ '@/views/Lobby')
const GameView = () => import(/* webpackPrefetch: true */ '@/views/GameView')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/slots',
    name: 'lobbyIndex',
    component: Lobby
  },
  {
    path: '/gameview',
    name: 'GameView',
    component: GameView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
