<template lang='pug'>
#app
  router-view(v-if="isGotVersion && isQueryChecked")
  #bgm
    mp3-player(ref="Mp3Player")
  el-drawer(custom-class="android-hint" v-if="!isSafari" :title="appName" :visible.sync="isPromptShow" direction="btt" :before-close="handleDrawerClose")
    .flex.flex-col.justify-around.items-center.h-full
      p {{ $t('Shared.PromptMsg') }}.
      button.gold(style="min-width: unset; margin-bottom: 0", @click="install", data-at-element="pwa-install") {{ $t('Shared.Install') }}
  .safari-hint(v-if="isSafariHintShow")
    .clock(v-if="hintCountDown > 0") {{ hintCountDown }}
    .close(v-else @click="isSafariHintShow = false")
    p {{ $t('Shared.PromptMsg') }}.
    p 1. {{ $t('Shared.SafariHint0') }}
    p 2. {{ $t('Shared.SafariHint1') }}
      img.arrow-up(src="@/assets/images/common/arrowUp.svg")
    p 3. {{ $t('Shared.SafariHint2') }}
      img(src="@/assets/images/common/squarePlus.svg")
    button.gold(@click="isIosTutorialShow = true") {{ $t('Shared.Introduction') }}
  ios-tutorial(v-if="isIosTutorialShow" @close="handleTutorialClose")
  div.loading-container.asset(v-if="!isContentLoaded")
    .progress-container
      .progress-bar(:style="{ width: barWidth + '%' }")
  div.loading-container(v-if="$store.state.app.IsLoading")
    .axe-box
      img.left-axe(src="@/assets/images/common/axe-left.webp", alt="axe")
      img.right-axe(src="@/assets/images/common/axe-right.webp", alt="axe")
    p.loading-text
      span L
      span O
      span A
      span D
      span I
      span N
      span G
  Rotate(v-show="isRotateShow")
  video.youtube-player(
    v-if="isSafariHintShow && isYoutubePlayerShow"
    autoplay
    muted
    playsinline
    controls
    ref="IOSVideo"
    @load="isYoutubePlayerLoaded = true"
  )
    source(:src="require(`@/assets/video/ios-pwa-${language === 'th' ? 'th' : 'en'}.mp4`)")
  img.youtube-player-close(v-if="isSafariHintShow && isYoutubePlayerShow && isYoutubePlayerLoaded" src="@/assets/images/promo-code/close.webp" @click="isYoutubePlayerShow = false")
</template>

<script>
import Mp3Player from '@/components/Mp3Player'
import Rotate from '@/views/Rotate'
import app from 'web-slot-module/vue-module/app'
import IosTutorial from 'web-slot-module/components/ios-tutorial'

export default {
  components: {
    Mp3Player,
    Rotate,
    IosTutorial
  },
  ...app
}

</script>

<style lang='scss' scoped>
@import '@/assets/scss/app.scss';
</style>
