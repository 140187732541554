import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import { language } from 'web-slot-module'

Vue.use(VueI18n)

export function getLanguage() {
  const chooseLanguage = store.getters.language
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase()
  const locales = Object.keys(language)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  silentTranslationWarn: true,
  // set locale messages
  messages: language
})

export default i18n
