<template lang='pug'>
div(class="table-wrapper")
  table
    thead(class="text-center font-sans")
      tr
        th(v-for="(item, index) in headers", :key="index")
          | {{ $t(item.locale) }}
    tbody(v-if="source.length > 0", class="text-center font-sans" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)")
      tr(v-for="(item, index) in source", :key="index" v-if="!isLoading")
        td(v-for="(column, columnIndex) in columns", :key="columnIndex")
          | {{ formatter(source[index], column, item[column.key], index) }}
    tbody(v-else, class="text-center font-sans" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)")
      tr(v-if="!isLoading")
        td(:colspan="columns.length")
          div No Data
</template>
<script>
export default {
  name: 'TableList',
  props: {
    source: {
      type: Array,
      default: () => {
        return []
      }
    },
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    formatter: {
      type: Function,
      default: () => {
      }
    },
    customClass: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/mixins';
  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      padding: 5px;
      border: 2px solid rgba(255,255,255,.2);
      border-top: none;
      border-left: none;
      color: #fff;
      font-size: 13px;
      @include ipad-width {
        font-size: 26px;
      }
      &:last-child {
        border-right: none
      }
    }
  }
</style>
