<template lang="pug">
div(class="modal-dialog")
  div(class="modal-content")
    div(class="modal-body")
      p {{ $t('Shared.RotateDeviceContent') }}
      img(src="@/assets/images/common/rotate-phone.webp")
</template>

<script>
export default {
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_mixins';
  .modal-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    z-index: 999999999;
    .modal-content {
      z-index: 11000;
      width: 95%;
      padding: 20px;
      height: 300px;
      color: #fff;
      position: fixed;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 24px;
      background: linear-gradient(45deg, #45c9ff 0%, #7bdbff 100%);
      box-shadow: inset 0 -1px 1px 2px #2a91bd;
      &:before {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        height: 15px;
        background: radial-gradient(ellipse 50% 100% at bottom, #9ae2ff 0%, #3895bc 15%, transparent 100%);
        z-index: 2;
      }
      &:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        background: radial-gradient(circle at 50% -20%, #158cba 0, #2980a4 10%, #1c7ba2 30%, #0e2f5a 80%);
      box-shadow: inset 0 2px 0 0px rgba(98,213,255,.4);
      border: 3px solid #000;
        z-index: -2;
        border-radius: 22px;
      }
      .modal-header {
        width: 100%;
        height: 35px;
        background-color: #3daae9;
        color: white;
        line-height: 35px;
      }
      .modal-body {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img {
          width: 100%;
          max-width: 170px;
          mix-blend-mode: lighten;
          filter: invert(1) grayscale(2) brightness(2);
        }
        p {
          font-family: Helvetica;
          line-height: 30px;
          margin: 50px auto 10px;
          text-shadow: 0 1px 1px #000, 0 1px 1px #000, 0 1px 1px #000;
          @include width768 {
            font-size: 30px;
          }
        }
      }
    }
  }
</style>
